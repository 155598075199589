import styled from '@emotion/styled';
import { ActivityComponentType } from '@stackflow/react';
import { AppScreen } from '../../stackflow/components';
import { PageParams } from '../../stackflow/types/params';
import city_background from '../../assets/Image/city_background.png'; // Tell webpack this JS file uses this image
import { Spacing } from '../common/Spacing';
import RankBoxItem from './components/RankBoxItem';
import MyRankItem from './components/MyRankItem';
import { useFlow } from '../../stackflow';
import SetNicknameModal from './components/SetNicknameModal';
import useFormContextHook from '../../hooks/useFormContextHook';
import { useGetScores } from '../../api/fetchGetScores';
import CountDownReset from './components/CountDownReset';
import RegionVS from './components/RegionVS';

export type HomePageParams = Pick<PageParams, 'from'>;

const HomePage: ActivityComponentType<HomePageParams> = () => {
  const { push } = useFlow();
  const { watch } = useFormContextHook();
  const userInfo = watch('userInfo');

  const { data } = useGetScores();

  const startHandler = () => {
    push('GamePage', {});
  };
  return (
    <AppScreen noAppBar>
      {!userInfo?.region && <SetNicknameModal />}
      <Wrapper>
        <TitleWrapper>
          <Title>이웃대전🥊</Title>
          <RegionVS />
          <BackGround />
        </TitleWrapper>
        <RankWrapper>
          <MyRankItem ranks={data?.data?.ranks ?? []} />
          <Spacing height={22} />
          <InfoText>
            이번 주 랭킹 <CountDownReset />
          </InfoText>
          <RankListWrapper>
            {data &&
              data?.data?.ranks
                ?.sort((a, b) => {
                  if (a.score === b.score) {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt)?.getTime();
                  }
                  return b.score - a.score;
                })
                ?.map((item, index) => {
                  return (
                    <div key={item.id + item.score + index}>
                      <RankBoxItem
                        rank={index + 1}
                        region={item.region}
                        nickname={item.nickname}
                        score={item.score}
                        message={item.message}
                      />
                      <Spacing height={9} />
                    </div>
                  );
                })}
          </RankListWrapper>
        </RankWrapper>
        <ButtonWrapper>
          <Button onClick={startHandler}>게임 시작</Button>
        </ButtonWrapper>
      </Wrapper>
    </AppScreen>
  );
};

export default HomePage;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: #faf5f4;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white, -3.5px 0 lightgray,
    0 3.5px lightgray, 3.5px 0 lightgray, 0 -3.5px lightgray;
  letter-spacing: 2px;
  line-height: 1.2;
  color: #eb5d0e;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Cafe24Ssurround';

  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 1;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 30vh;
  position: relative;
  flex-shrink: 0;
`;

const BackGround = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${city_background});
  background-repeat: repeat-x;
  background-size: 60%;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  opacity: 0.4;
`;

const RankWrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  height: auto;
  overflow: scroll;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const InfoText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 161.7%;
  padding: 0 26px;

  color: #5b5b5b;

  span {
    font-weight: 400;
    font-size: 11px;
    line-height: 161.7%;

    #remain_time {
      color: #eb5d0e;
    }
  }
`;

const RankListWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 9px 26px 26px 26px;
  background: #ffffff;
  gap: 9px;
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 20px 24px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Button = styled.div`
  background: #eb5d0e;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 161.7%;
  text-align: center;
  color: #ffffff;

  padding: 7px;
`;
