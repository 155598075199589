import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { queryClient } from '../../../api/base';
import { useGetScores } from '../../../api/fetchGetScores';
import { usePostMyScore } from '../../../api/fetchPostMyScore';
import CircularProgress from '../../../assets/CircularProgress';
import smallCupCake from '../../../assets/Image/small_cupcake.png';
import useFormContextHook from '../../../hooks/useFormContextHook';
import { useFlow } from '../../../stackflow';
import { Spacing } from '../../common/Spacing';

type Props = {
  resultScore: number;
};

const FinishGameModal = ({ resultScore }: Props) => {
  const [showSpinner, setShowSpinner] = React.useState(true);
  const { setValue, watch } = useFormContextHook();
  const userInfo = watch('userInfo');
  const inputMessage = watch('input-message') ?? '';

  const { mutate } = usePostMyScore();
  const { pop } = useFlow();
  const { data } = useGetScores();

  const myData = data?.data?.ranks?.find((score) => score.id === userInfo.id);
  const myScore = myData?.score ?? 0;
  const totalScore = (myScore + resultScore).toLocaleString();

  useEffect(() => {
    if (myData && myData.message) {
      setValue('input-message', myData.message);
    }
  }, [myData]);

  const setUserScoreAndMessage = async () => {
    if (inputMessage.length > 20) return alert('메시지는 20자 이하로 입력해주세요');
    const message = inputMessage.replaceAll(' ', '').length === 0 ? ' ' : inputMessage;

    console.log('userInfo', userInfo, message);
    await mutate(
      {
        id: userInfo.id,
        nickname: userInfo.nickname,
        region: userInfo.region,
        score: resultScore,
        message: message,
      },
      {
        onSuccess: (res) => {
          if (res.success) {
            queryClient.refetchQueries('scores');
            // setTimeout(() => {
            console.log('refetch-------------------');
            // }, 1000);
            // refetch();
          } else {
            console.log('실패');
          }
          pop();
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setShowSpinner(false);
    }, 1500);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const renderResult = () => {
    return (
      <>
        <img
          src={smallCupCake}
          alt=""
          style={{
            width: '50px',
            height: '50px',
          }}
        />
        <Spacing height={16} />

        <Text>
          <span>축하합니다!</span>
          <br />
          <span>{resultScore}개</span>를 추가했어요
        </Text>
        <Spacing height={5} />
        <SubText>
          누적 컵케익 <span>{totalScore}개</span>
        </SubText>
        <Spacing height={10} />
        <Divider sx={{ width: '100%' }} />
        <Spacing height={17} />
        <SubInfo>
          이웃들에게
          <br />
          하고 싶은 말을 남겨보세요
        </SubInfo>
        <Spacing height={12} />
        <Input
          placeholder="ex. 오예 내가 젤 잘해!"
          value={inputMessage}
          onChange={(e) => {
            setValue('input-message', e.target.value);
          }}
        />
        <Spacing height={26} />
        <SubmitButton onClick={setUserScoreAndMessage} color={'#EB5D0E'} able>
          등록하기
        </SubmitButton>
      </>
    );
  };

  return (
    <ModalWrapper>
      {showSpinner ? <CircularProgress /> : <Wrapper>{renderResult()}</Wrapper>}
    </ModalWrapper>
  );
};

export default FinishGameModal;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(40, 40, 40, 0.8);

  z-index: 9999;
  top: 0;
  left: 0;

  padding: 34px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 46px 18px 18px 18px;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 161.7%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #3f3f3f;

  span {
    color: #eb5d0e;
  }
`;

const SubText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 161.7%;
  color: #a9a9a9;
  text-align: center;

  span {
    font-weight: 600;
    color: #eb5d0e;
  }
`;

const SubInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 161.7%;
  color: #a9a9a9;
  text-align: center;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #3f3f3f;
  }
`;

const SubmitButton = styled.div<{ color: string; able: boolean }>`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 161.7%;
  text-align: center;
  background-color: ${(props) => (props.able ? props.color : 'rgba(235, 93, 14, 0.4)')};
  color: #ffffff;
`;

const Input = styled.input`
  width: calc(100% - 36px);
  padding: 6px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 161.7%;
  /* identical to box height, or 26px */

  color: #3f3f3f;

  &::placeholder {
    color: #bababa;
    font-style: italic;
  }
`;
