import { useActions } from '@stackflow/react';
import { useStepActions } from '@stackflow/react';
import { stackflow } from '@stackflow/react';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';

import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { ROUTE_PATHS } from './routes';

import { storage } from '../hooks/useStorage';
import HomePage from '../components/Home/Index';
import GamePage from '../components/GamePage';
import '@stackflow/plugin-basic-ui/index.css';
import TestPage from '../components/TestPage';

const initStackflow = () => {
  const onBoardStorage = storage('onBoard');

  return stackflow({
    transitionDuration: 350,
    activities: {
      HomePage,
      GamePage,
      TestPage,
    },
    plugins: [
      historySyncPlugin({
        routes: {
          HomePage: ROUTE_PATHS.HOME,
          GamePage: ROUTE_PATHS.PLAY,
          TestPage: ROUTE_PATHS.TEST,
        },
        fallbackActivity: () => {
          return 'HomePage';
        },
      }),
      basicRendererPlugin(),
      basicUIPlugin({ theme: 'cupertino' }),
    ],
  });
};

const {
  Stack,
  activities,
  useFlow: useOriginFlow,
  useStepFlow: useOriginStepFlow,
} = initStackflow();

export type TypeActivities = typeof activities;
export type TypeActivityNames = keyof TypeActivities;
export type TypeUseFlow = typeof useOriginFlow;
export type TypeUseStepFlow = typeof useOriginStepFlow;

const useFlow: TypeUseFlow = () => useActions<TypeActivities>();
const useStepFlow: TypeUseStepFlow = (activityName: TypeActivityNames) =>
  useStepActions(activityName as never);

export { Stack, activities, useFlow, useStepFlow };
