import axios from 'axios';
import { QueryClient } from 'react-query';

export const API_BASE_URL =
  process.env.REACT_APP_ENV === 'dev'
    ? 'http://localhost:3001/api'
    : 'https://daangn-game.fly.dev/api';

export const initFetchInstance = (baseURL: string) =>
  axios.create({
    baseURL,
    timeout: 5000,
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchInstance = () => initFetchInstance(API_BASE_URL);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      useErrorBoundary: false,
    },
  },
});
