import styled from '@emotion/styled';
import React, { useMemo } from 'react';

interface Props {
  region: string;
}

const RegionChip = ({ region }: Props) => {
  const renderRegionText = useMemo(() => {
    switch (region) {
      case 'gwanak':
        return '관악구';
      case 'bundang':
        return '분당구';
      default:
        return '관악구';
    }
  }, [region]);

  return <Wrapper>{renderRegionText}</Wrapper>;
};

export default RegionChip;

const Wrapper = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #3f3f3f;
  font-weight: 700;
`;
