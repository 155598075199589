import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_BASE_URL, fetchInstance } from './base';

export type MyScoreProps = {
  id: string;
  nickname: string;
  message: string;
  region: string;
  score: number;
};

export type MyScoreResponse = {
  success: boolean;
  data?: {
    id: string;
    _id: string;
  };
};

const postMyScorePath = `${API_BASE_URL}/game/my-score`;

export const fetchPostMyScore = async (req: MyScoreProps) => {
  const result = await fetchInstance().post(postMyScorePath, req);
  return result.data;
};

export const usePostMyScore = () =>
  useMutation<MyScoreResponse, AxiosError, MyScoreProps>('my-score', fetchPostMyScore);
