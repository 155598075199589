import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo } from 'react';
import { AppScreen } from '../../stackflow/components';
import city_background from '../../assets/Image/city_background.png';
// import karrot from '../../assets/Image/Karrot.png';
import cupCake from '../../assets/Image/CupCake.png';
import ClickParticle, { ClickAnimationProps } from './components/ClickParticle';
import ControllPanel from './components/ControllPanel';
import OnBoardModal from './components/OnBoardModal';
import FinishGameModal from './components/FinishGameModal';

const GamePage = () => {
  const [score, setScore] = React.useState(0);
  const [Particle, setParticle] = React.useState<Omit<ClickAnimationProps, 'onDestroy'>[]>([]);
  const [gameState, setGameState] = React.useState<'ready' | 'playing' | 'end'>('ready');

  const touchHandler = useCallback(
    (e: React.TouchEvent<HTMLImageElement>) => {
      if (gameState !== 'playing') return;
      setScore((prev) => prev + 1);
      setParticle((prev) => [
        ...prev.filter((el) => {
          const isDiff15Sec = new Date().getTime() - Number(el.id) > 1500;
          if (isDiff15Sec) return false;
          return true;
        }),
        {
          id: `${new Date().getTime()}`,
          posX: e.targetTouches[0].clientX,
          posY: e.targetTouches[0].clientY,
        },
      ]);
    },
    [gameState]
  );

  // const ImageScale = useMemo(() => {
  //   const ratio = timer / 30000; // 0부터 1까지의 비율 계산
  //   const minScale = 1; // 최소 scale 값
  //   const maxScale = 0.1; // 최대 scale 값
  //   const newScale = maxScale + (minScale - maxScale) * ratio;
  //   return newScale;
  // }, [timer]);

  return (
    <AppScreen noAppBar preventSwipeBack>
      <Wrapper>
        <ControllPanel setGameState={setGameState} currentScore={score} />
        <PlayGroundWrapper>
          <CurrentPointWrapper>{score}</CurrentPointWrapper>
          <DaangnButton
            src={cupCake}
            onTouchStart={touchHandler}
            isPlaying={gameState === 'playing'}
          />
        </PlayGroundWrapper>
        <BackGround />
        {Particle.map((item) => {
          return (
            <ClickParticle
              key={item.id}
              id={item.id}
              posX={item.posX}
              posY={item.posY}
              onDestroy={() => {
                setParticle((prev) =>
                  prev.filter((el) => {
                    const isDiff15Sec = new Date().getTime() - Number(el.id) > 1500;
                    if (isDiff15Sec) return false;
                    return true;
                  })
                );
              }}
            />
          );
        })}
        {gameState === 'ready' && (
          <OnBoardModal
            onStart={() => {
              setGameState('playing');
            }}
          />
        )}

        {gameState === 'end' && <FinishGameModal resultScore={score} />}
      </Wrapper>
    </AppScreen>
  );
};

export default GamePage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #faf5f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PlayGroundWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const BackGround = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${city_background});
  background-repeat: repeat-x;
  background-size: 130%;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  opacity: 0.4;
  z-index: 1;
`;

const CurrentPointWrapper = styled.div`
  width: 150px;
  padding: 0 5px 5px 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 40px;
  line-height: 161.7%;

  color: #eb5d0e;
  background: #ffffff;
  border-radius: 0 0 75px 75px;
  border: 2px solid #eb5d0e;
`;

const DaangnButton = styled.img<{ isPlaying: boolean }>`
  width: 35%;
  height: auto;
  z-index: 5;
  transition: transform 0.1s linear;
  transform: scale(1);

  &:active {
    transform: scale(0.9);
  }
`;
