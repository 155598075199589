import styled from '@emotion/styled';
import React from 'react';
import { MyScoreProps } from '../../../api/fetchPostMyScore';
import useFormContextHook from '../../../hooks/useFormContextHook';
import { Spacing } from '../../common/Spacing';

const MyRankItem = ({ ranks }: { ranks: MyScoreProps[] }) => {
  const { watch } = useFormContextHook();
  const userInfo = watch('userInfo');
  if (!userInfo || !userInfo?.nickname) return null;

  const { nickname } = userInfo;

  const myInfo = ranks.find((el) => el.id === userInfo.id);
  const myRank = ranks.findIndex((el) => el.id === userInfo.id) + 1;

  if (!myRank || myRank === 0) {
    return (
      <Wrapper>
        <UserInfo>
          <span>{nickname}</span>님은 아직 점수가 없어요.
        </UserInfo>
      </Wrapper>
    );
  }
  return myRank === 0 ? (
    <Wrapper>
      <UserInfo>아직 순위가 집계되지 않았어요</UserInfo>
    </Wrapper>
  ) : (
    <Wrapper>
      <UserInfo>
        <span>{nickname}</span>님은 <span>{myRank}위</span>에요!
      </UserInfo>
      <Spacing height={10} />
      <MyBox>
        <Rank>{myRank}위</Rank>
        <Nickname>{nickname}</Nickname>
        <Point>{myInfo?.score?.toLocaleString()}</Point>
      </MyBox>
    </Wrapper>
  );
};

export default MyRankItem;

const Wrapper = styled.div`
  width: 100%;
  padding: 26px 26px 0 26px;
`;

const MyBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 14px;

  gap: 14px;
`;

const Rank = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 161.7%;
  color: #5b5b5b;
`;

const Point = styled.div`
  font-size: 12px;
  line-height: 161.7%;
  text-align: right;
  color: #7c7c7c;
`;

const Nickname = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 161.7%;
  color: #7c7c7c;
  flex: 1;
`;

const UserInfo = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 161.7%;
  letter-spacing: -0.02em;
  color: #3f3f3f;

  span {
    font-weight: 700;
  }
`;
