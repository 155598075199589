import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useGetScores } from '../../../api/fetchGetScores';
import useFormContextHook from '../../../hooks/useFormContextHook';
interface Props {
  currentScore: number;
  setGameState: React.Dispatch<React.SetStateAction<'ready' | 'playing' | 'end'>>;
}

const ControllPanel = ({ setGameState, currentScore }: Props) => {
  const { watch } = useFormContextHook();
  const userInfo = watch('userInfo');
  const { data } = useGetScores();

  const myData = data?.data?.ranks?.find((score) => score.id === userInfo.id);
  const myScore = myData?.score ?? 0;
  const totalScore = (myScore + currentScore).toLocaleString();

  const pauseHandler = useCallback(() => {
    setGameState('end');
  }, []);

  return (
    <ControlleWrapper>
      <TimerWrapper>
        <span>누적 컵케익</span>
        <span>{totalScore}개</span>
      </TimerWrapper>
      <div></div>
      <TimerWrapper onClick={pauseHandler}>
        {/* <PauseIcon sx={{ color: '#cc6023', lineHeight: '23px' }} /> */}
        <span>그만하기</span>
      </TimerWrapper>
    </ControlleWrapper>
  );
};

export default ControllPanel;

const ControlleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  z-index: 100;
  padding: 31px 18px 0 18px;
`;

const TimerWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 13px;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 161.7%;
    color: #cc6023;
  }
`;
