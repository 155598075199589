import { ReactElement } from 'react';
import useGetNativeEventBridge from './hooks/useGetNativeEventBridge';
import './utils/firebase';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from './stackflow';
import { storage } from './hooks/useStorage';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/base';

function App(): ReactElement {
  useGetNativeEventBridge();

  // 앱 시작일 저장 - 앱 시작일 기준으로 몇주차인지 계산하기 위함
  const userInfo = storage('userInfo').get();

  const formMathod = useForm({
    defaultValues: { userInfo },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <FormProvider {...formMathod}>
        <Stack />
      </FormProvider>
    </QueryClientProvider>
  );
}

export default App;
