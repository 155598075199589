import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_BASE_URL, fetchInstance } from './base';

interface IGame extends Document {
  id: string;
  nickname: string;
  score: number;
  rank: number;
  message: string;
  region: string;
  createdAt: Date;
}

export type RegisterUserResponse = {
  success: boolean;
  data?: {
    ranks: IGame[];
  };
};

const getGameScoresPath = `${API_BASE_URL}/game/scores`;

export const fetchGetScores = async () => {
  const result = await fetchInstance().get(getGameScoresPath);
  return result.data;
};

export const useGetScores = () =>
  useQuery<RegisterUserResponse, AxiosError>('scores', fetchGetScores, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
