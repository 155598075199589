import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import RegionChip from '../../common/RegionChip';
import { Spacing } from '../../common/Spacing';

interface Props {
  rank: number;
  nickname: string;
  region: string;
  score: number;
  message: string;
}

const RankBoxItem = ({ rank, nickname, region, score, message }: Props) => {
  return (
    <Wrapper>
      <CurrentRank rank={rank}>{rank}등</CurrentRank>
      {message.trim().length === 0 ? (
        <InfoWrapper>
          <UserInfoWrapper noMessage>
            <UserNameWrapper>
              <RegionChip region={region} />
              <UserInfoText>{nickname}</UserInfoText>
            </UserNameWrapper>
            <UserInfoText>{score.toLocaleString()}</UserInfoText>
          </UserInfoWrapper>
        </InfoWrapper>
      ) : (
        <InfoWrapper>
          <Message rank={rank}>{message}</Message>
          <Spacing height={3} />
          <Divider sx={{ width: '100%' }} />
          <Spacing height={3} />
          <UserInfoWrapper>
            <UserNameWrapper>
              <RegionChip region={region} />
              <UserInfoText>{nickname}</UserInfoText>
            </UserNameWrapper>
            <UserInfoText>{score.toLocaleString()}</UserInfoText>
          </UserInfoWrapper>
        </InfoWrapper>
      )}
    </Wrapper>
  );
};

export default RankBoxItem;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 9px 14px;
  gap: 15px;
`;

const CurrentRank = styled.div<{ rank: number }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 26px;
  color: ${({ rank }) => {
    if (rank === 1) {
      return '#EB5D0E';
    } else if (rank === 2) {
      return '#FF8946';
    } else if (rank === 3) {
      return '#EB8E39';
    }
    return '#383838';
  }};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
`;

const Message = styled.div<{ rank: number }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: ${({ rank }) => {
    if (rank === 1) {
      return '#EB5D0E';
    } else if (rank === 2) {
      return '#FF8946';
    } else if (rank === 3) {
      return '#EB8E39';
    }
    return '#383838';
  }};
`;

const UserInfoWrapper = styled.div<{ noMessage?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ noMessage }) => (noMessage ? '26px' : 'auto')};
`;

const UserInfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 161.7%;
  color: #7c7c7c;
`;

const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
