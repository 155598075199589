import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useGetScores } from '../../../api/fetchGetScores';

const RegionVS = () => {
  const { data } = useGetScores();

  const bundangList = data?.data?.ranks.filter((el) => el.region === 'bundang');
  const gwanakList = data?.data?.ranks.filter((el) => el.region === 'gwanak');

  const bundangTotalScore = bundangList?.reduce((acc, cur) => acc + cur.score, 0) ?? 0;
  const gwanakTotalScore = gwanakList?.reduce((acc, cur) => acc + cur.score, 0) ?? 0;

  const bundangPercentage = useMemo(() => {
    if (bundangTotalScore === 0 && gwanakTotalScore === 0) return 50;
    return parseFloat(
      ((bundangTotalScore / (bundangTotalScore + gwanakTotalScore)) * 100).toFixed(2)
    );
  }, [bundangTotalScore, gwanakTotalScore]);

  const gwanakPercentage = useMemo(() => {
    if (bundangTotalScore === 0 && gwanakTotalScore === 0) return 50;
    return parseFloat(
      ((gwanakTotalScore / (bundangTotalScore + gwanakTotalScore)) * 100).toFixed(2)
    );
  }, [bundangTotalScore, gwanakTotalScore]);

  return (
    <Wrapper>
      <RowWrapper>
        <RegionItem>
          <div>분당구</div>
          <div>({bundangTotalScore?.toLocaleString()})</div>
        </RegionItem>
        <PercentBar>
          <LeftBar width={bundangPercentage + '%'}>{bundangPercentage}%</LeftBar>
          <Center>🔥</Center>
          <RightBar width={gwanakPercentage + '%'}>{gwanakPercentage}%</RightBar>
        </PercentBar>
        <RegionItem>
          <div>관악구</div>
          <div>({gwanakTotalScore?.toLocaleString()})</div>
        </RegionItem>
      </RowWrapper>
    </Wrapper>
  );
};

export default RegionVS;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 20px;
  z-index: 99;
`;

const PercentBar = styled.div`
  flex: 1;
  height: 20px;
  background-color: #e5e5e5;
  border-radius: 5px;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  /* overflow: hidden; */
`;

const LeftBar = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 100%;
  background-color: #eb5d0e;

  color: white;
  font-weight: 600;
  font-size: 14px;

  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999;
`;

const RightBar = styled(LeftBar)`
  border-radius: 0 5px 5px 0;

  color: #3b3b3b;
  background-color: transparent;
  z-index: 9999;
`;

const Center = styled.div`
  width: 1px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-size: 20px;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  span {
    font-size: 14px;
    font-weight: 700;
    color: #3f3f3f;
  }
`;

const RegionItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #3f3f3f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
