import * as React from 'react';

import smallCupCake from '../../../assets/Image/small_cupcake.png';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export interface ClickAnimationProps {
  id: string;
  posX: number;
  posY: number;
  onDestroy: (id: string) => void;
}

const ClickParticle: React.FC<ClickAnimationProps> = ({ id, posX, posY, onDestroy }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onDestroy(id);
    }, 1500);
    return clearTimeout(timer);
  }, [id, onDestroy]);

  return <Image posX={posX} posY={posY} src={smallCupCake} alt="" />;
};

export default React.memo(ClickParticle);

const animation = keyframes`
  100% {
    transform: translateY(-500%) rotate(360deg);
    opacity: 0;
  }
`;

const Image = styled.img<{
  posX: number;
  posY: number;
}>`
  width: 50px;
  height: 50px;

  position: absolute;
  z-index: 10;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: ${animation};
  animation-fill-mode: forwards;

  left: ${(props) => props.posX - 25}px;
  top: ${(props) => props.posY - 25}px;

  pointer-events: none;
`;
