import styled from '@emotion/styled';
import hand_click from '../../../assets/Image/hand_click.png';

type Props = {
  onStart: () => void;
};

const OnBoardModal = ({ onStart }: Props) => {
  return (
    <ModalWrapper onClick={onStart}>
      <Wrapper>
        <PointerIcon src={hand_click} />
        <Text>
          컵케익을 눌러보세요!
          <br />
          누적 컵케익 수를 늘려
          <br />
          높은 점수를 획득하세요.
        </Text>
      </Wrapper>
    </ModalWrapper>
  );
};

export default OnBoardModal;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  width: 200px;
  height: auto;
  background: rgba(80, 80, 80, 0.4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 30px 20px 24px 20px;
`;

const PointerIcon = styled.img`
  width: 70px;
  height: auto;
`;

const Text = styled.div`
  margin-top: 10px;
  font-weight: 800;
  font-size: 14px;
  line-height: 161.7%;
  text-align: center;
  color: #ffffff;
`;
