import styled from '@emotion/styled';
import { ActivityComponentType } from '@stackflow/react';
import { AppScreen } from '../../stackflow/components';
import { PageParams } from '../../stackflow/types/params';
import { Sender, Receiver } from 'sonicnet';
import { useEffect, useState } from 'react';

import SonicSocket from '../../lib/sonic-socket.js';
import SonicServer from '../../lib/sonic-server.js';
import SonicCoder from '../../lib/sonic-coder.js';
import React from 'react';

import VConsole from 'vconsole';
export type HomePageParams = Pick<PageParams, 'from'>;

const TestPage: ActivityComponentType<HomePageParams> = () => {
  const [data, setData] = useState('');
  const [sonicServer, setSonicServer] = useState<any>();
  const [sonicSocket, setSonicSocket] = useState<any>();

  const ref = React.useRef<HTMLDivElement>(null);
  const soundPlay = () => {
    console.log('play sound');
    sonicSocket.send('checkin');
    setData((prev) => prev + '\n' + 'send test');
  };
  const soundStop = () => {};

  function onIncomingChat(message: string) {
    console.log('chat inbound.', message);
    (ref.current as HTMLDivElement)?.append(`${message}\n`);
  }

  const listenHandler = () => {
    console.log('listen');
    sonicServer.start();
    sonicServer.on('message', onIncomingChat);
  };

  useEffect(() => {
    console.log('started');
    const ALPHABET = ' abcdefghijklmnopqrstuvwxyz';
    // Create an ultranet server.
    const sonicServerTemp = new SonicServer({ alphabet: ALPHABET, debug: true });
    // Create an ultranet socket.
    const sonicSocketTemp = new SonicSocket({ alphabet: ALPHABET });

    // Create a sonic coder.
    setSonicServer(sonicServerTemp);
    setSonicSocket(sonicSocketTemp);
  }, []);

  useEffect(() => {
    if (!sonicServer) return;
    sonicServer.on('message', onIncomingChat);
    sonicServer.start();
  }, [sonicServer]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev') {
      const vConsole = new VConsole();
    }
  }, []);

  return (
    <AppScreen noAppBar>
      <Wrapper>
        <h1>{data}</h1>
        <ButtonWrapper>
          <Button onClick={soundPlay}>SOUND PLAY</Button>
          <Button onClick={soundStop}>SOUND STOP</Button>

          <Button onClick={listenHandler}>LISTEN</Button>

          <div ref={ref}></div>
        </ButtonWrapper>
      </Wrapper>
    </AppScreen>
  );
};

export default TestPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: #faf5f4;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 20px 24px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Button = styled.div`
  background: #eb5d0e;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 161.7%;
  text-align: center;
  color: #ffffff;

  padding: 7px;
`;
