import styled from '@emotion/styled';
import { Spacing } from '../../common/Spacing';
import useFormContextHook from '../../../hooks/useFormContextHook';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid';
import { useEffect, useMemo } from 'react';
import { useFlow } from '../../../stackflow';

const SetNicknameModal = () => {
  const { setValue, watch } = useFormContextHook();
  const { push } = useFlow();
  const inputNickname = watch('input-nickname') ?? '';
  const inputRegion = watch('select-region') ?? '';

  useEffect(() => {
    setValue('input-nickname', '');
    setValue('select-region', 'gwanak');
  }, []);

  const setUserInfoHandler = () => {
    if (!inputNickname || inputNickname.length === 0 || inputNickname.trim().length === 0)
      return alert('닉네임을 입력해주세요');
    if (!inputRegion || inputRegion.length === 0 || inputRegion.trim().length === 0)
      return alert('지역을 선택해주세요');
    if (inputNickname.length > 12) return alert('닉네임은 12자 이하로 입력해주세요');

    const uuid = new DeviceUUID().get() || uuidv4();

    setValue('userInfo', {
      id: uuid + inputNickname + Date.now(),
      nickname: inputNickname,
      region: inputRegion,
    });
    push('GamePage', {});
  };

  const setSelectRegionHandler = (region: string) => {
    setValue('select-region', region);
  };

  const isValid = useMemo(() => {
    if (!inputNickname || inputNickname.length === 0 || inputNickname.trim().length === 0)
      return false;
    if (!inputRegion || inputRegion.length === 0 || inputRegion.trim().length === 0) return false;
    if (inputNickname.length > 12) return false;
    return true;
  }, [inputNickname, inputRegion]);

  return (
    <ModalWrapper>
      <Wrapper>
        <Text>
          사용하실 <span>닉네임</span>을 입력해주세요
        </Text>

        <Spacing height={2} />
        <SubText>모임 닉네임과 같은 이름을 입력해주세요</SubText>
        <Spacing height={15} />

        <Input
          placeholder="ex. 게임왕"
          value={inputNickname}
          onChange={(e) => {
            setValue('input-nickname', e.target.value);
          }}
        />
        <Spacing height={26} />
        <SelectLabel>지역 선택</SelectLabel>
        <RegionButtonWrapper>
          <label htmlFor="gwanak">
            <input
              type="radio"
              id="gwanak"
              name="region"
              value="gwanak"
              defaultChecked
              onChange={(e) => {
                setSelectRegionHandler(e.target.value);
              }}
            />
            관악구
          </label>
          <label htmlFor="bundang">
            <input
              type="radio"
              id="bundang"
              name="region"
              value="bundang"
              onChange={(e) => {
                setSelectRegionHandler(e.target.value);
              }}
            />
            분당구
          </label>
        </RegionButtonWrapper>

        <Spacing height={26} />
        <Button onClick={setUserInfoHandler} color={'#EB5D0E'} able={isValid}>
          시작하기
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SetNicknameModal;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(40, 40, 40, 0.8);

  z-index: 9999;
  top: 0;
  left: 0;

  padding: 34px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 18px 18px 18px 18px;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 161.7%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #3f3f3f;

  span {
    font-weight: 800;
    color: #eb5d0e;
  }
`;

const SubText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 161.7%;
  color: #a9a9a9;
`;

const Button = styled.div<{ color: string; able: boolean }>`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 161.7%;
  text-align: center;
  background-color: ${(props) => (props.able ? props.color : 'rgba(235, 93, 14, 0.4)')};
  color: #ffffff;
`;

const Input = styled.input`
  width: calc(100% - 36px);
  padding: 6px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 161.7%;
  /* identical to box height, or 26px */

  color: #3f3f3f;
`;

const SelectLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 161.7%;
  color: #3f3f3f;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
`;

const RegionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  input {
    margin-right: 5px;
  }
`;
