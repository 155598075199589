import React, { useEffect, useState } from 'react';

const CountDownReset: React.FC = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const now = new Date();
    const today = now.getDay();
    const daysUntilMonday = 1 + 7 - today;
    const monday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + daysUntilMonday,
      0,
      0,
      0
    ); // 다음주 월요일 계산

    const timeLeft = monday.getTime() - now.getTime(); // 월요일까지 남은 밀리초 계산
    console.log('timeLeft', today, daysUntilMonday);

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    const countdownString = `${days}일 ${hours}시간 남음`;
    setCountdown(countdownString);
  }, []);

  return (
    <span>
      (초기화까지 <span id="remain_time">{countdown}</span>)
    </span>
  );
};

export default CountDownReset;
